import { PLAYER_REGEX } from "~/utils/constants"

// State
export const state = () => ({
	games: [],
	loadingGames: false,
	providers: [],
	loadingProviders: false,
	players: [],
	playerIds: [],
	playerNames: [],
	phones: [],
	nicknames: [],
	bankAccountNumber: [],
	loadingPlayers: false,
	searchingCancelToken: {}
})

// Actions
export const actions = {
	async games({ commit }, params) {
		try {
			commit('loadingGames', true)
			return await this.$axios
				.get(`/games/all-game-code-and-game-name`, { params })
				.then((response) => {
					commit('games', response.data)
					commit('loadingGames', false)
				})
		} catch (error) {
			commit('loadingGames', false)
		}
	},

	providers({ commit }, params) {
		commit('loadingProviders', true)
		this.$axios
			.get(`/game-providers/game-provider-name/${params}`)
			.then((response) => {
				commit('providers', response.data)
				commit('loadingProviders', false)
			})
			.catch((e) => {
				commit('loadingProviders', false)
			})
	},
	players({ commit }, data) {
		const params = JSON.parse(JSON.stringify(data))
		if (params?.player_id) {
			params.player_id = params?.player_id?.toLowerCase()
			const isMatch = PLAYER_REGEX
			if (!isMatch.test(params?.player_id)) {
				return null;
			}
		}
		this.$axios
			.get(`/master/player-parameters`, { params })
			.then((response) => {
				commit('players', response.data)
				commit('loadingPlayers', false)
			})
			.catch((e) => {
				commit('loadingPlayers', false)
			})
	},
	async playerSearch({ commit, state }, data) {
		const params = JSON.parse(JSON.stringify(data))
		if (state.searchingCancelToken.searchingPlayerId) {
			state.searchingCancelToken.searchingPlayerId.cancel('')
		}
		commit('setSearchingCancelToken', { searchingPlayerId: this.$axios.CancelToken.source() })

		if (params?.player_id) {
			params.player_id = params?.player_id?.toLowerCase()
			const isMatch = PLAYER_REGEX
			if (!isMatch.test(params?.player_id)) {
				return null;
			}
		}
		commit('loadingPlayers', true)
		const response = await this.$axios
			.get(`/master/player-parameters`,
				{
					params,
					cancelToken: state.searchingCancelToken.searchingPlayerId.token
				})
			.then((response) => {
				// data is the response from API
				// type is the types of searching [username, playerId, playerName, phone, bankAccountNumber]
				commit('playerSearch', { data: response.data, type: params, })
				commit('loadingPlayers', false)
				return response.data
			})
			.catch((e) => {
				commit('loadingPlayers', false)
			})
		return response
	},

}

// Mutations
export const mutations = {
	games(state, response) {
		state.games = response
	},
	loadingGames(state, type) {
		state.loadingGames = type
	},
	providers(state, data) {
		state.providers = data
	},
	loadingProviders(state, type) {
		state.loadingProviders = type
	},
	players(state, data) {
		state.players = data;
	},
	playerSearch(state, data) {
		const { type } = data
		if (type.player_id) {
			state.playerIds = [...data.data]
		} else if (type.name) {
			state.playerNames = [...data.data]
		} else if (type.phone) {
			state.phones = [...data.data]
		} else if (type.nickname) {
			state.nicknames = [...data.data]
		} else if (type.bank_account_number) {
			state.bankAccountNumber = [...data.data]
		}
	},
	loadingPlayers(state, type) {
		state.loadingPlayers = type
	},
	// cancel token
	setSearchingCancelToken: (state, data) => {
		const oldData = { ...state.searchingCancelToken }
		state.searchingCancelToken = {
			...oldData, ...data
		}
	}
}